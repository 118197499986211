export const errorHelper = (formik, value) => ({
  error: formik.errors[value] && formik.touched[value] ? true : false,
  helperText:
    formik.errors[value] && formik.touched[value] ? formik.error[value] : null
});
export const getValuesFromQueryString = values => {
  if (!values) {
    return false;
  }
  try {
    return values.split(',');
  } catch (err) {
    return false;
  }
};

export const getCamelCaseOfSentences = str => {
  return str?.toLowerCase()?.replace(/(^|\s)[a-z]/g, L => L.toUpperCase());
};
// export const get

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}