import request, { gql } from 'graphql-request';
import { GRAPHQL_ENDPOINT } from '../../pages/_app';

const MedicationStrategyListDocument = `query MedicationStrategies($hospitalId: String, $healthSystemId: String, $size: Int, $page: Int) {
  medicationStrategies(hospitalId: $hospitalId, healthSystemId: $healthSystemId, size: $size, page: $page) {
    drugAlternative
    drugStandard
    facility
    id
    opportunitySize
    opportunityType
    primaryTherapeutics
    scope
    status
    normalizedDrg
    alternativeNormalizedDrg
  }
}`;

export function getMedicationStrategyListQuery(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyListDocument,
    queryVariables,
    headers
  );
}

export const MedicationStrategyOptionsDocument = `
    query MedicationStrategyOptions {
  medicationStrategyOptions {
    medicationOptions {
      label
      value
    }
    strengthOptions {
      label
      value
    }
    doseFormOptions {
      label
      value
    }
    ndcOptions {
      label
      value
    }
    hospitalOptions {
      label
      value
    }
    therapeuticOptions {
      label
      value
    }
  }
}
    `;

export function getMedicationStrategyOptionsQuery(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyOptionsDocument,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisDocument = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
  ) {
    drugPrice {
      title
      subtitle
      current
      recommended
    }
    lengthOfStay {
      title
      subtitle
      current
      recommended
    }
    medSpendPerPatient {
      title
      subtitle
      current
      recommended
    }
    medSpendPerPatientDay {
      title
      subtitle
      current
      recommended
    }
    overallMedSpendPerPatient {
      title
      subtitle
      current
      recommended
    }
    readmission {
      title
      subtitle
      current
      recommended
    }
    monthlySpend {
      title
      subtitle
      current
      recommended
    }
    utilizationInTherapeutics {
      title
      subtitle
      current
      recommended
      longDescription
    }
    facility {
      title
      subtitle
      current
      recommended
    }
    medicationAttributeCount {
      title
      subtitle
      current
      recommended
    }
    patientCount {
      title
      subtitle
      current
      recommended
    }
    patientDemographicV2 {
        title
        longDescription
        subtitle
        current
        recommended
    }
    physicianSpend {
      title
      subtitle
      current
      recommended
    }
    physicianCount {
      title
      subtitle
      current
      recommended
    }
  }
}
`;

export function getMedicationStrategyAbAnalysisQuery(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisDocument,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisGeneral = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $secondaryTherapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $perPatient: Boolean
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    secondaryTherapeutics: $secondaryTherapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    perPatient: $perPatient
  ) {
    drugPrice {
      title
      subtitle
      current
      recommended
      recommendedSubtitle
    }

    medSpendPerPatient {
      title
      subtitle
      current
      recommended
    }
    lengthOfStay {
      title
      subtitle
      current
      recommended
    }
    careCost {
      title
      longDescription
      subtitle
      current
      recommended
    }
    allMedication {
      title
      longDescription
      subtitle
      current
      recommended
    }
    readmission {
      title
      longDescription
      subtitle
      current
      recommended
    }
    targetMedication {
      title
      longDescription
      subtitle
      current
      recommended
    }
  }
}
`;
export function getMedicationStrategyGeneral(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisGeneral,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisEfficacy = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $secondaryTherapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    secondaryTherapeutics: $secondaryTherapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
  ) {
    patientCount {
      title
      subtitle
      current
      recommended
    }
  }
}
`;
export function getMedicationStrategyEfficacy(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisEfficacy,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisTherapeutic = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $secondaryTherapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
  $patientPercent: Boolean
  $primaryPatientCount: Boolean
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    secondaryTherapeutics: $secondaryTherapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
    patientPercent: $patientPercent
    primaryPatientCount: $primaryPatientCount
  ) {
    patientCountInTherapeutics {
      title
      subtitle
      current
      recommended
      longDescription
    }
  }
}
`;

export function getMedicationStrategyTherapeutic(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisTherapeutic,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisPatientDemographic = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $secondaryTherapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    secondaryTherapeutics: $secondaryTherapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
  ) {
    patientDemographicV2 {
        title
        longDescription
        subtitle
        current
        recommended
    }
  }
}
`;
export function getMedicationStrategyPatientDemographic(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisPatientDemographic,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisPhysicianBreakDown = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $secondaryTherapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
  $patientPercent: Boolean
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    secondaryTherapeutics: $secondaryTherapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
    patientPercent: $patientPercent
  ) {
    physicianBreakdown {
      title
      subtitle
      current
      recommended
    }
  }
}
`;

export function getMedicationStrategyFacilityBreakDown(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisFacilityBreakDown,
    queryVariables,
    headers
  );
}

export const MedicationStrategyAbAnalysisFacilityBreakDown = `
query MedicationStrategyAbAnalysis(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $secondaryTherapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
  $patientPercent: Boolean
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    secondaryTherapeutics: $secondaryTherapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
    patientPercent: $patientPercent
  ) {
    facilityBreakdown {
      title
      subtitle
      current
      recommended
    }
  }
}

`;
export function getMedicationStrategyPhysicianBreakDown(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyAbAnalysisPhysicianBreakDown,
    queryVariables,
    headers
  );
}

export const physicianSpendViewAllQuery = `query PhysicianSpendViewAll(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
  ) {
    physicianSpendViewAll {
      current
      recommended
      subtitle
      title
    }
  }
}
`;

export function getMedicationStrategyPhysicianspendViewAll(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    physicianSpendViewAllQuery,
    queryVariables,
    headers
  );
}

export const utilizationSpendViewAllquery = `query PhysicianSpendViewAll(
  $current: String!
  $recommended: String!
  $currentStrengths: [String]
  $currentBrandNames: [String]
  $currentNdcs: [String]
  $currentRoutes: [String]
  $recommendedStrengths: [String]
  $recommendedBrandNames: [String]
  $recommendedNdcs: [String]
  $recommendedRoutes: [String]
  $therapeutics: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $page: Int
  $size: Int
) {
  medicationStrategyAbAnalysis(
    current: $current
    recommended: $recommended
    currentStrengths: $currentStrengths
    currentBrandNames: $currentBrandNames
    currentNdcs: $currentNdcs
    currentRoutes: $currentRoutes
    recommendedStrengths: $recommendedStrengths
    recommendedBrandNames: $recommendedBrandNames
    recommendedNdcs: $recommendedNdcs
    recommendedRoutes: $recommendedRoutes
    therapeutics: $therapeutics
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    page: $page
    size: $size
  ) {
    utilizationInTherapeuticsViewAll {
      current
      recommended
      subtitle
      title
    }
  }
}
`;

export function getMedicationStrategyUtilizationspendViewAll(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationSpendViewAllquery,
    queryVariables,
    headers
  );
}

export const MedicationStrategyInsight = `
query MedicationStrategyInsight($medicationStrategyId: Int!) {
  medicationStrategyInsight(medicationStrategyId: $medicationStrategyId) {
    id
    proposal
    scope
    opportunitySize
    opportunityType
    drugStandard
    drugAlternative
    rationale
    normalizedDrg
    timestampUtc
  }
}

`;

export function getMedicationStrategyInsight(
  queryVariables = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    MedicationStrategyInsight,
    queryVariables,
    headers
  );
}

const medicationOptionByIdQuery = `query MedicationOptionById($id: String, $query: String) {
  medicationOption(id: $id, query:$query) {
    id
    label
    value
  }
}
`;

export function getMedicationOptionById(queryVariables = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    medicationOptionByIdQuery,
    queryVariables,
    headers
  );
}

export const medicationFilterOptionsQuery = `query MedicationAttributesOptions(
  $medicationGenericName: String
  $therapeuticNormalizedDrgs: [String]
  $hospitals: [String]
) {
  medicationAttributesOptions(
    medicationGenericName: $medicationGenericName
    therapeuticNormalizedDrgs: $therapeuticNormalizedDrgs
    hospitals: $hospitals
  ) {
    brandNames
    ndcs
    route
    strength
  }
}`;

export function getmedicationFilterOptions(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    medicationFilterOptionsQuery,
    options,
    headers
  );
}

export const therapeuticOptionsQuery = `query TherapeuticOption(
  $medicationGenericName: String
  $medicationRoute: [String]
  $medicationStrength: [String]
  $medicationBrandName: [String]
  $medicationNdcs: [String]
  $hospitals: [String]
  $physicianIds: [String]
  $startDate: String
  $endDate: String
  $primary: Boolean
  $getAllTherapeutics: Boolean
) {
  therapeuticOption(
    medicationGenericName: $medicationGenericName
    medicationRoute: $medicationRoute
    medicationStrength: $medicationStrength
    medicationBrandName: $medicationBrandName
    medicationNdcs: $medicationNdcs
    hospitals: $hospitals
    physicianIds: $physicianIds
    startDate: $startDate
    endDate: $endDate
    primary: $primary
    getAllTherapeutics: $getAllTherapeutics
  ) {
    label
    value
  }
}

`;

export function getTherepeuticOptions(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, therapeuticOptionsQuery, options, headers);
}
