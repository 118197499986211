import request from 'graphql-request';
import { GRAPHQL_ENDPOINT } from '../../pages/_app';

const ModelUtilizationListDocument = `
query($healthSystemId:String, $hospitalId:String, $size: Int, $page: Int) {
  opportunities(healthSystemId:$healthSystemId, hospitalId:$hospitalId, size: $size, page: $page)  {
    id
    healthSystemId
    normalizedDrg
    scope
    sequenceNumber
    drugName
    reasonId
    impact
    score
    action
    trend
    therapeuticDescription,
    cause
    context
  }
}`;

export function getModelUtilizationListQuery(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    ModelUtilizationListDocument,
    queryVariables,
    headers
  );
}

export function getModelUtilizationInsightsData(queryVariables, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    modeUtilizationinsight,
    queryVariables,
    headers
  );
}

export const modeUtilizationinsight = `query Opportunity($opportunityId: Int!, $hospitals: [String]) {
  opportunity(id: $opportunityId, hospitals: $hospitals) {
    id
    healthSystemId
    normalizedDrg
    scope
    sequenceNumber
    drugName
    reasonId
    impact
    score
    action
    trend
    therapeuticDescription
    cause
    periodBegin
    periodEnd
    beforePeriodBegin
    beforePeriodEnd
    modelUtilizationSupplement {
      id
      modelUtilizationId
      genericName
      route
      reasonId
    }
  }
}

`;

export function getModelUtilizationOverview(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, modeUtilizationOverview, options, headers);
}

export const modeUtilizationOverview = `query MonthlyOpportunities($monthlyOpportunitiesId: Int!, $hospitals: [String]) {
  monthlyOpportunities(id: $monthlyOpportunitiesId, hospitals: $hospitals) {
    endDate
    opportunities {
      fieldName
      subName
      value
    }
    startDate
  }
}
`;

export const modeUtilizationOppertunityTopPhysicianGraphs = `query TopPhysicianBasedOnOpportunity($modelUtilizationId: Int!) {
  topPhysiciansBasedOnOpportunity(modelUtilizationId: $modelUtilizationId) {
    physicianName
    physicianSpend
  }
}
`;

export function getModeUtilizationOppertunityTopPhysicianGraphs(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    modeUtilizationOppertunityTopPhysicianGraphs,
    options,
    headers
  );
}

const topPhysicianBasedOnOppertunityPercentGraph = `query TopPhysicianBasedOnOpportunity($modelUtilizationId: Int! ) {
  topPhysiciansBasedOnOpportunity(modelUtilizationId: $modelUtilizationId) {
    physicianName
    percentOfSpend
  }
}`;

export function getModeUtilizationOppertunityTopPhysicianPercentageGraph(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topPhysicianBasedOnOppertunityPercentGraph,
    options,
    headers
  );
}

const toMedicationsBasedOnOppertunityGraphs = `query TopMedicationBasedOnOpportunity($modelUtilizationId: Int!) {
  topMedicationBasedOnOpportunity(modelUtilizationId: $modelUtilizationId) {
    medicationName
    monthlyUtilization
    monthlyUtilizationPercent
  }
}
`;

export function useModeUtilizationOppertunityTopMedicationGraphs(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    toMedicationsBasedOnOppertunityGraphs,
    options,
    headers
  );
}

const topTherepeuticsBasedOnMedication = `query TopTherapeuticBasedOnMedication(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
) {
  topTherapeuticBasedOnMedication(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
  ) {
    monthlyPatient
    monthlyPatientPercent
    monthlyUtilization
    monthlyUtilizationPercent
    spendPerPatient
    therapeuticId
    therapeuticName
  }
}
`;

export function useTopTherepeuticsBaesdOnMedicationGraph(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topTherepeuticsBasedOnMedication,
    options,
    headers
  );
}

const topMedicationsBasedOnTherepeuticsGraphs = `query TopMedicationsBasedOnTherapeutic(
  $therapeuticSlug: String!
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
) {
  topMedicationsBasedOnTherapeutic(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
  ) {
    medicationName
    monthlyUtilization
    monthlyUtilizationPercent
    monthlyPatient
    spendPerPatient
  }
}

`;

export function useTopMedicationssBaesdOnMTherepeuticsGraph(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topMedicationsBasedOnTherepeuticsGraphs,
    options,
    headers
  );
}

export function useModeUtilizationTrends(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, modeUtilizationTrends, options, headers);
}

export const modeUtilizationTrends = `query OpportunityGraph(
  $opportunityGraphId: Int!
  $frequency: String
) {
  opportunityGraph(
    id: $opportunityGraphId
    frequency: $frequency
  ) {
    medicationUtilized {
      date
      medicationUtilized
    }
    patientLoad {
      date
      patientLoad
    }
    activePhysician {
      activePhysician
      date
    }
  }
}

`;

export function useModeUtilizationTrendSearch(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    ModelUtilizationListDocument,
    options,
    headers
  );
}

export const subFiltersQueryHospitalMedication = `query filterOptions($healthSystemId:String!){
  hospitalOption(healthSystemId:$healthSystemId){
    label
    value
  }
}
`;

export function getSubFiltersHosiptalMedication(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    subFiltersQueryHospitalMedication,
    options,
    headers
  );
}

const ndcOptionsQuery = `query ndcOptionBasedOnOpportunity($id: Int!) {
  ndcOptionBasedOnOpportunity(id: $id) {
    label
    value
  }
}`;

export function useNdcOptionsQuery(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, ndcOptionsQuery, options, headers);
}

const routeOptionsQuery = `query routeOption {
  routeOption{
    value
  }
}
`;

export function useRouteOptionsQuery(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, routeOptionsQuery, options, headers);
}

const brandOptionsQuery = `query brandNameOption {
  brandNameOption{
    value
  }
}`;

export function useBrandOptionsQuery(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, brandOptionsQuery, options, headers);
}

export const getFilterPagesUtilizationQuery = `query HomePageSearch($healthSystemId: String!, $queryString: String!, $size: Int, $page: Int){
  homepageSearch(
    queryString: $queryString
    healthSystemId: $healthSystemId
    size: $size
    page: $page
  ) {
    drugSearchResults {
      id
      brandName
      genericName
      route
      drugForm
    }
    theraputicSearchResults {
      id
      normalizedDrg
      description
      longDescription
      drgCode
      drgCodeType
    }
  }
}`;
export function getUtilizationSearch(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getFilterPagesUtilizationQuery,
    options,
    headers
  );
}

export const getFilterPagesUtilizationDrugSearchQuery = `query HomePageSearch($healthSystemId: String!, $queryString: String!, $size: Int, $page: Int){
  homepageSearch(
    queryString: $queryString
    healthSystemId: $healthSystemId
    size: $size
    page: $page
  ) {
    drugSearchResults {
      id
      brandName
      genericName
      route
      drugForm
    }
  }
}`;
export function getUtilizationDrugSearch(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getFilterPagesUtilizationDrugSearchQuery,
    options,
    headers
  );
}

export const getFilterPagesUtilizationTheraputicSearchQuery = `query HomePageSearch($healthSystemId: String!, $queryString: String!, $size: Int, $page: Int){
  homepageSearch(
    queryString: $queryString
    healthSystemId: $healthSystemId
    size: $size
    page: $page
  ) {
    theraputicSearchResults {
      id
      normalizedDrg
      description
      longDescription
      drgCode
      drgCodeType
    }
  }
}`;
export function getUtilizationTheraputicSearch(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getFilterPagesUtilizationTheraputicSearchQuery,
    options,
    headers
  );
}

export const getMedicationPageSearchQuery = `query HomePageSearch($healthSystemId: String!, $queryString: String!, $size: Int, $page: Int){
  homepageSearch(
    queryString: $queryString
    healthSystemId: $healthSystemId
    size: $size
    page: $page
  ) {
    drugSearchResults {
      id
      brandName
      genericName
      route
      drugForm
    }
}
}`;
export function useMedicationSearchPage(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getMedicationPageSearchQuery,
    options,
    headers
  );
}

export function getOverviewWhenMedicationSelectedFromSearch(
  options = {},
  headers = {}
) {
  return request(GRAPHQL_ENDPOINT, medicationSelectedQuery, options, headers);
}

export const medicationSelectedQuery = `query MonthlyMedicationCard(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
) {
  monthlyMedicationCard(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
  ) {
    drugPrice {
      value
      subtitle
    }
    monthlyUtilization
    monthlyPhysician
    therapeuticGroups
  }
}
`;

export function getOverviewWhenTherepauticSelectedFromSearch(
  options = {},
  headers = {}
) {
  return request(GRAPHQL_ENDPOINT, therepauticSelectedQuery, options, headers);
}

export const therepauticSelectedQuery = `query MonthlyAnalysisCard(
  $therapeuticSlug: String!
  $hospitals: [String]
  $startDate: String
  $endDate: String
) {
  monthlyAnalysisCard(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
  ) {
    monthlyAveragePatient
    monthlyNumberOfPhysician
    monthlyUtilization
    numberOfDrugsAccordingFor80PerSpend
  }
}
`;

const topPhysicianBasedOnMedicationSelectedOnUtilization = `query MedicationOpportunityGraph(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
  $frequency: String
) {
  medicationOpportunityGraph(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
    frequency: $frequency
  ) {
    endDate
    startDate
    medicationTopPhysicians {
      date
      physicianId
      physicianName
      spend
    }
  }
}
`;

export function getTopPhysicianBasedOnMedicationSelectedOnUtilization(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topPhysicianBasedOnMedicationSelectedOnUtilization,
    options,
    headers
  );
}

const topPhysicianBasedOnMedicationSelectedOnUtilizationPercent = `query TopPhysiciansBaseOnMedication(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
) {
  topPhysiciansBaseOnMedication(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
  ) {
    physicianId
    percentOfSpend
    numberOfPatient
    physicianSpend
    spendPerPatient
    physicianName
  }
}

`;

export function getTopPhysicianBasedOnMedicationSelectedOnUtilizationPercent(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topPhysicianBasedOnMedicationSelectedOnUtilizationPercent,
    options,
    headers
  );
}

const topPhysicianBasedOnTherapeuticSelectedOnUtilizationPercent = `query TopPhysiciansBasedOnTherapeutics(
  $therapeuticSlug: String!
  $hospitals: [String]
  $startDate: String
  $endDate: String
) {
  topPhysiciansBasedOnTherapeutics(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
  ) {
    physicianId
    physicianName
    numberOfPatient
    percentOfSpend
    physicianSpend
    spendPerPatient
  }
}

`;

export function getTopPhysicianBasedOnTherapeuticSelectedOnUtilizationPercent(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topPhysicianBasedOnTherapeuticSelectedOnUtilizationPercent,
    options,
    headers
  );
}

const trendsGraphsBasedOnMedicationOnTrendsPage = `query MedicationOpportunityGraph(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
  $therapeutics: [String]
  $frequency: String
) {
  medicationOpportunityGraph(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
    therapeutics: $therapeutics
    frequency: $frequency
  ) {
    startDate
    endDate
    medicationUtilized {
      date
      medicationUtilized
    }
    medicationPatientLoad {
      date
      patientLoad
    }
  }
}
`;

export function getTrendsGraphsBasedOnMedicationOnTrendsPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    trendsGraphsBasedOnMedicationOnTrendsPage,
    options,
    headers
  );
}

const trendsGraphsBasedOnTherapeuticnOnTrendsPage = `query TherapeuticOpportunityGraph(
  $therapeuticSlug: String!
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $frequency: String
  $page: Int
  $size: Int
) {
  therapeuticOpportunityGraph(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    frequency: $frequency
    size: $size
    page: $page
  ) {
    endDate
    startDate
    therapeuticMedicationUtilized {
      date
      medicationUtilized
    }
    therapeuticPatientLoad {
      date
      patientLoad
    }
  }
}
`;

export function getTrendsGraphsBasedOnTherapeuticnOnTrendsPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    trendsGraphsBasedOnTherapeuticnOnTrendsPage,
    options,
    headers
  );
}

const topRanksGraphsbasedOnMedicationTopPhysiciansOnUtilizationPage = `query MedicationOpportunityGraph(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
  $frequency: String
  $size: Int
  $page: Int
) {
  medicationOpportunityGraph(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
    frequency: $frequency
    size: $size
    page: $page
  ) {
    endDate
    startDate
    medicationTopPhysicians {
      date
      physicianId
      physicianName
      spend
    }
  }
}

`;

const topRanksGraphsbasedOnMedicationTopTherapeuticsOnUtilizationPage = `query MedicationOpportunityGraph(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
  $frequency: String
  $size: Int
  $page: Int
) {
  medicationOpportunityGraph(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
    frequency: $frequency
    size: $size
    page: $page
  ) {
    endDate
    startDate
    medicationTopTherapeutics {
      date
      spend
      therapeuticId
      therapeuticName
    }
  }
}
`;

export function getTopRanksGraphsbasedOnTopPhysiciansOnUtilizationPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topRanksGraphsbasedOnMedicationTopPhysiciansOnUtilizationPage,
    options,
    headers
  );
}

export function getTopRanksGraphsbasedOnTopTherapeuticsOnUtilizationPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topRanksGraphsbasedOnMedicationTopTherapeuticsOnUtilizationPage,
    options,
    headers
  );
}

const topRanksGraphsbasedOnTherepeuticOnUtilizationPage = `query TherapeuticOpportunityGraph(
  $therapeuticSlug: String!
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $frequency: String
  $size: Int
  $page: Int
) {
  therapeuticOpportunityGraph(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    frequency: $frequency
    size: $size
    page: $page
  ) {
    therapeuticTopMedications {
      date
      medicationId
      medicationName
      spend
    }
    therapeuticTopPhysicians {
      date
      physicianId
      physicianName
      spend
    }
  }
}
`;

export function getTopRanksGraphsbasedOnTherepeuticOnUtilizationPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topRanksGraphsbasedOnTherepeuticOnUtilizationPage,
    options,
    headers
  );
}

const topRanksTherapeuticTopMedicationsGraphsbasedOnTherepeuticOnUtilizationPage = `query TherapeuticOpportunityGraph(
  $therapeuticSlug: String!
  $hospitals: [String]
  $size: Int
  $page: Int
  $startDate: String
  $endDate: String
  $frequency: String
) {
  therapeuticOpportunityGraph(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    size: $size
    page: $page
    startDate: $startDate
    endDate: $endDate
    frequency: $frequency
  ) {
    startDate
    endDate
    therapeuticTopMedications {
      date
      medicationId
      medicationName
      spend
    }
   
  }
}
`;

export function getTopRanksTherapeuticTopMedicationsGraphsbasedOnTherepeuticOnUtilizationPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topRanksTherapeuticTopMedicationsGraphsbasedOnTherepeuticOnUtilizationPage,
    options,
    headers
  );
}

const topRanksTherapeuticTopPhysiciansGraphsbasedOnTherepeuticOnUtilizationPage = `query TherapeuticOpportunityGraph(
  $therapeuticSlug: String!
  $hospitals: [String]
  $size: Int
  $page: Int
  $startDate: String
  $endDate: String
  $frequency: String
) {
  therapeuticOpportunityGraph(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    size: $size
    page: $page
    startDate: $startDate
    endDate: $endDate
    frequency: $frequency
  ) {
    startDate
    endDate
    therapeuticTopPhysicians {
      date
      physicianId
      physicianName
      spend
    }
  }
}
`;

export function getTopRanksTherapeuticTopPhysiciansGraphsbasedOnTherepeuticOnUtilizationPage(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    topRanksTherapeuticTopPhysiciansGraphsbasedOnTherepeuticOnUtilizationPage,
    options,
    headers
  );
}

const comparePageGraphsDataBasedOnMedication = `query MedicationCompare($medicationGenericName: String!, $strengths: [String], $brandNames: [String], $ndcs: [String], $routes: [String], $hospitals: [String], $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $afterEndDate: String, $therapeutics: [String]) {
  medicationCompare(medicationGenericName: $medicationGenericName, strengths: $strengths, brandNames: $brandNames, ndcs: $ndcs, routes: $routes, hospitals: $hospitals, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, afterEndDate: $afterEndDate, therapeutics: $therapeutics) {
    medicationUtilizationMedicationCompare {
      name
      medicationUtilizationBefore
      medicationUtilizationAfter
    }
    patientLoadMedicationCompare {
      name
      patientLoadBefore
      patientLoadAfter
    }
    drugPriceMedicationCompare {
      name
      drugPriceBefore
      drugPriceAfter
      drugPriceAfterUnit
      drugPriceBeforeUnit
    }
  }
}
`;

const comparePageGraphsDataBasedOnMedicationUtilization = `query MedicationCompare($medicationGenericName: String!, $strengths: [String], $brandNames: [String], $ndcs: [String], $routes: [String], $hospitals: [String], $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $afterEndDate: String, $therapeutics: [String]) {
  medicationCompare(medicationGenericName: $medicationGenericName, strengths: $strengths, brandNames: $brandNames, ndcs: $ndcs, routes: $routes, hospitals: $hospitals, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, afterEndDate: $afterEndDate, therapeutics: $therapeutics) {
    medicationUtilizationMedicationCompare {
      name
      medicationUtilizationBefore
      medicationUtilizationAfter
    }
  }
}`;

const comparePageGraphsDataBasedOnMedicationPatientLoad = `query MedicationCompare($medicationGenericName: String!, $strengths: [String], $brandNames: [String], $ndcs: [String], $routes: [String], $hospitals: [String], $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $afterEndDate: String, $therapeutics: [String]) {
  medicationCompare(medicationGenericName: $medicationGenericName, strengths: $strengths, brandNames: $brandNames, ndcs: $ndcs, routes: $routes, hospitals: $hospitals, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, afterEndDate: $afterEndDate, therapeutics: $therapeutics) {
    patientLoadMedicationCompare {
      name
      patientLoadBefore
      patientLoadAfter
    }
  }
}`;

const comparePageGraphsDataBasedOnMedicationDrugPrice = `query MedicationCompare($medicationGenericName: String!, $strengths: [String], $brandNames: [String], $ndcs: [String], $routes: [String], $hospitals: [String], $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $afterEndDate: String, $therapeutics: [String]) {
  medicationCompare(medicationGenericName: $medicationGenericName, strengths: $strengths, brandNames: $brandNames, ndcs: $ndcs, routes: $routes, hospitals: $hospitals, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, afterEndDate: $afterEndDate, therapeutics: $therapeutics) {
    drugPriceMedicationCompare {
      name
      drugPriceBefore
      drugPriceAfter
    }
  }
}`;

const comparePageGraphsDataBasedOnMedicationPatientPercent = `query MedicationCompareShowMore($medicationGenericName: String!, $strengths: [String], $brandNames: [String], $ndcs: [String], $routes: [String], $hospitals: [String], $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $afterEndDate: String, $therapeutics: [String], $page: Int, $size: Int) {
  MedicationCompareShowMore(medicationGenericName: $medicationGenericName, strengths: $strengths, brandNames: $brandNames, ndcs: $ndcs, routes: $routes, hospitals: $hospitals, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, afterEndDate: $afterEndDate, therapeutics: $therapeutics, page: $page, size: $size) {
    patientPercentChangeShowAll {
      therapeuticName
      therapeuticLongName
      beforePercentageOfPatientRecievedMed
      afterPercentageOfPatientRecievedMed
    }
  }
}`;

const comparePageGraphsDataBasedOnMedicationTopTherapeutics = `query MedicationCompareShowMore($medicationGenericName: String!, $strengths: [String], $brandNames: [String], $ndcs: [String], $routes: [String], $hospitals: [String], $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $afterEndDate: String, $therapeutics: [String], $page: Int, $size: Int) {
  MedicationCompareShowMore(medicationGenericName: $medicationGenericName, strengths: $strengths, brandNames: $brandNames, ndcs: $ndcs, routes: $routes, hospitals: $hospitals, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, afterEndDate: $afterEndDate, therapeutics: $therapeutics, page: $page, size: $size) {
    topTherapeuticChangesShowAll {
      therapeuticName
      therapeuticLongName
      spendBefore
      spendAfter
    }
  }
}`;

export function getComparePageGraphsDataBasedOnMedicationUtilization(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsDataBasedOnMedicationUtilization,
    options,
    headers
  );
}

export function getComparePageGraphsDataBasedOnMedicationPatientLoad(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsDataBasedOnMedicationPatientLoad,
    options,
    headers
  );
}

export function getComparePageGraphsDataBasedOnMedicationDrugPrice(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsDataBasedOnMedicationDrugPrice,
    options,
    headers
  );
}

export function getComparePageGraphsDataBasedOnMedicationPatientPercent(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsDataBasedOnMedicationPatientPercent,
    options,
    headers
  );
}

export function getComparePageGraphsDataBasedOnMedicationTopTherapeutics(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsDataBasedOnMedicationTopTherapeutics,
    options,
    headers
  );
}

export function getComparePageGraphsDataBasedOnMedication(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsDataBasedOnMedication,
    options,
    headers
  );
}

const comparePageGraphsBasedOnTherepeutics = `query TherapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    medicationUtilization {
      medicationUtilizationBefore
      medicationUtilizationAfter
    }

    patientLoadChange {
      patientLoadBefore
      patientLoadAfter
    }

  }
}`;

const comparePageGraphsBasedOnTherepeuticsUtilization = `query TherapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    medicationUtilization {
      medicationUtilizationBefore
      medicationUtilizationAfter
    }
  }
}`;

const comparePageGraphsBasedOnTherepeuticsUtilizationChange = `query therapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    medicationUtilizationChange {
      medicationName
      beforePercentageOfPatientRecievedMed
      afterPercentageOfPatientRecievedMed
    }
  }
}`;

const comparePageGraphsBasedOnTherepeuticsDrugPrice = `query TherapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    drugPriceChanges {
      medicationName
      beforePrice
      afterPrice
      costDetail
    }
  }
}`;

const comparePageGraphsBasedOnTherepeuticsPatientLoad = `query TherapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    patientLoadChange {
      patientLoadBefore
      patientLoadAfter
    }
  }
}`;

const comparePageGraphsBasedOnTherepeuticsPatientPercent = `query therapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int 
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    patientPercentChange {
      medicationName
      beforePatientPercent
      afterPatientPercent
    }
  }
}`;

const comparePageGraphsBasedOnTherepeuticsUtilizationPerPatient = `query TherapeuticCompare(
  $therapeuticSlug: String!
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  therapeuticCompare(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    medicationUtilizationPerPatientChange {
      medicationName
      beforeUtilizationPerPatient
      afterUtilizationPerPatient
    }
  }
}`;

export function getComparePageGraphsBasedOnTherepeuticsUtilization(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeuticsUtilization,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnTherepeuticsUtilizationChange(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeuticsUtilizationChange,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnTherepeuticsDrugPrice(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeuticsDrugPrice,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnTherepeuticsPatientLoad(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeuticsPatientLoad,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnTherepeuticsPatientPercent(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeuticsPatientPercent,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnTherepeuticsUtilizationPerPatient(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeuticsUtilizationPerPatient,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnTherepeutics(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnTherepeutics,
    options,
    headers
  );
}

const comparePageGraphsBasedOnOpportunityUtilizationChange = `query opportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    medicationUtilizationChange {
      medicationName
      beforePercentageOfPatientRecievedMed
      afterPercentageOfPatientRecievedMed
    }
  }
}`;

const comparePageGraphsBasedOnOpportunityDrugPrice = `query TherapeuticCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $page: Int
  $size: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    page: $page
    size: $size
  ) {
    drugPriceChanges {
      medicationName
      beforePrice
      afterPrice
      costDetail
    }
  }
}`;

const comparePageGraphsBasedOnOpportunityPatientPercent = `query opportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    patientPercentChange {
      medicationName
      beforePatientPercent
      afterPatientPercent
    }
  }
}`;

const comparePageGraphsBasedOnOpportunityPerPatient = `query opportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    medicationUtilizationPerPatientChange {
      medicationName
      beforeUtilizationPerPatient
      afterUtilizationPerPatient
    }
  }
}`;


export function getComparePageGraphsBasedOnOpportunityUtilizationChange(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnOpportunityUtilizationChange,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnOpportunityDrugPrice(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnOpportunityDrugPrice,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnOpportunityPatientPercent(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnOpportunityPatientPercent,
    options,
    headers
  );
}

export function getComparePageGraphsBasedOnOpportunityPerPatient(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    comparePageGraphsBasedOnOpportunityPerPatient,
    options,
    headers
  );
}

const getQueryTopTherapeuticBasedOnMedication = `query TopTherapeuticBasedOnMedication(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $therapeutics: [String]
  $size: Int
  $page: Int
) {
  topTherapeuticBasedOnMedication(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    therapeutics: $therapeutics
    size: $size
    page: $page
  ) {
    therapeuticId
    therapeuticName
    monthlyUtilization
    monthlyUtilizationPercent
    monthlyPatient
    spendPerPatient
    monthlyPatientPercent
  }
}
`;

export function getTopTherapeuticBasedOnMedication(options = {}, headers = {}) {
  return request(
    GRAPHQL_ENDPOINT,
    getQueryTopTherapeuticBasedOnMedication,
    options,
    headers
  );
}

const getQueryTopMedicationsBasedOnTherapeutic = `query TopMedicationsBasedOnTherapeutic(
  $therapeuticSlug: String!
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
) {
  topMedicationsBasedOnTherapeutic(
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
  ) {
    medicationName
    monthlyUtilization
    monthlyUtilizationPercent
    monthlyPatient
    monthlyPatientPercent
  }
}

`;

export function getTopMedicationsBasedOnTherapeutic(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    getQueryTopMedicationsBasedOnTherapeutic,
    options,
    headers
  );
}

const queryTopRankMedTrendsViewAllBasedOnTherapeutic = `query UtilizationPageViewAllQuery(
  $medicationGenericName: String
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $therapeutics: [String]
  $modelUtilizationId: Int
  $therapeuticSlug: String
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
) {
  utilizationPageViewAllQuery(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    therapeutics: $therapeutics
    modelUtilizationId: $modelUtilizationId
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
  ) {
    topRankMedTrendsViewAllBasedOnTherapeutic {
      id
      name
      monthlyUtilization
      monthlyUtilizationPercent
      spendPerPatient
      data {
        month
        value
      }
    }
  }
}

`;

export function getQueryTopRankMedTrendsViewAllBasedOnTherapeutic(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    queryTopRankMedTrendsViewAllBasedOnTherapeutic,
    options,
    headers
  );
}

const queryTopRankPhysicianTrendsViewAllBasedOnMedication = `query UtilizationPageViewAllQuery(
  $medicationGenericName: String
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $therapeutics: [String]
  $modelUtilizationId: Int
  $therapeuticSlug: String
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
) {
  utilizationPageViewAllQuery(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    therapeutics: $therapeutics
    modelUtilizationId: $modelUtilizationId
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
  ) {
    topRankPhysicianTrendsViewAllBasedOnMedication {
      id
      name
      monthlyUtilization
      monthlyUtilizationPercent
      spendPerPatient
      data {
        month
        value
      }
    }
  }
}

`;

export function getQueryTopRankPhysicianTrendsViewAllBasedOnMedication(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    queryTopRankPhysicianTrendsViewAllBasedOnMedication,
    options,
    headers
  );
}

const queryTopRankPhysicianTrendsViewAllBasedOnTherapeutic = `query UtilizationPageViewAllQuery(
  $medicationGenericName: String
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $therapeutics: [String]
  $modelUtilizationId: Int
  $therapeuticSlug: String
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
) {
  utilizationPageViewAllQuery(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    therapeutics: $therapeutics
    modelUtilizationId: $modelUtilizationId
    therapeuticSlug: $therapeuticSlug
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
  ) {
    topRankPhysicianTrendsViewAllBasedOnTherapeutic {
      id
      name
      monthlyUtilization
      monthlyUtilizationPercent
      spendPerPatient
      data {
        month
        value
      }
    }
  }
}
`;

export function getQueryTopRankPhysicianTrendsViewAllBasedOnTherapeutic(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    queryTopRankPhysicianTrendsViewAllBasedOnTherapeutic,
    options,
    headers
  );
}

const queryTopRankTherapeuticsTrendsViewAllBasedOnMedication = `query UtilizationPageViewAllQuery(
  $medicationGenericName: String
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $therapeutics: [String]
  $therapeuticSlug: String
  $modelUtilizationId: Int
  $hospitals: [String]
  $startDate: String
  $endDate: String
  $size: Int
  $page: Int
) {
  utilizationPageViewAllQuery(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    therapeutics: $therapeutics
    therapeuticSlug: $therapeuticSlug
    modelUtilizationId: $modelUtilizationId
    hospitals: $hospitals
    startDate: $startDate
    endDate: $endDate
    size: $size
    page: $page
  ) {
    topRankTherapeuticsTrendsViewAllBasedOnMedication {
      id
      name
      monthlyUtilization
      monthlyUtilizationPercent
      spendPerPatient
      data {
        month
        value
      }
    }
  }
}

`;

export function getQueryTopRankTherapeuticsTrendsViewAllBasedOnMedication(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    queryTopRankTherapeuticsTrendsViewAllBasedOnMedication,
    options,
    headers
  );
}

const therapeuticDetailQuery = `query GetTherapeuticDetail($therapeuticSlug: String) {
  getTherapeuticDetail(therapeuticSlug: $therapeuticSlug) {
    short
    long
  }
}
`;

export function getTherapeuticDetail(options = {}, headers = {}) {
  return request(GRAPHQL_ENDPOINT, therapeuticDetailQuery, options, headers);
}

export const compareGraphsWhenUtilizationIdIsSelectedQuery = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
  ) {
    medicationUtilization {
      medicationUtilizationBefore
      medicationUtilizationAfter
    }
    medSpendPerPatient {
      medSpendPerPatientAfter
      medSpendPerPatientBefore
    }
    patientLoadChange {
      patientLoadBefore
      patientLoadAfter
    }
  }
}`;

export const compareGraphsWhenUtilizationIdIsSelectedQueryUtilization = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    medicationUtilization {
      medicationUtilizationBefore
      medicationUtilizationAfter
    }
  }
}`;

export const compareGraphsWhenUtilizationIdIsSelectedQueryUtilizationChange = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    medicationUtilizationChange {
      medicationName
      beforePercentageOfPatientRecievedMed
      afterPercentageOfPatientRecievedMed
    }
  }
}`;

export const compareGraphsWhenUtilizationIdIsSelectedQueryDrugPrice = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    drugPriceChanges {
      medicationName
      beforePrice
      afterPrice
      costDetail
    }
  }
}`;

export const compareGraphsWhenUtilizationIdIsSelectedQueryPatientLoad = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    patientLoadChange {
      patientLoadBefore
      patientLoadAfter
    }
  }
}`;

export const compareGraphsWhenUtilizationIdIsSelectedQueryPatientPercent = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    patientPercentChange {
      medicationName
      beforePatientPercent
      afterPatientPercent
    }
  }
}`;

export const compareGraphsWhenUtilizationIdIsSelectedQueryUtilizationPerPatient = `query OpportunityCompare(
  $modelUtilizationId: Int!
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $size: Int
  $page: Int
) {
  opportunityCompare(
    modelUtilizationId: $modelUtilizationId
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    size: $size
    page: $page
  ) {
    medicationUtilizationPerPatientChange {
      medicationName
      beforeUtilizationPerPatient
      afterUtilizationPerPatient
    }
  }
}`;

export function getCompareGraphsWhenUtilizationIdIsSelectedQuery(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    compareGraphsWhenUtilizationIdIsSelectedQuery,
    options,
    headers
  );
}

export const utilizationTherapeuticCompareMedicationUtilizationChangeShowAll = `query TherapeuticCompareShowMore($therapeuticSlug: String!, $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $page: Int, $size: Int, $afterEndDate: String) {
  therapeuticCompareShowMore(therapeuticSlug: $therapeuticSlug, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, page: $page, size: $size, afterEndDate: $afterEndDate) {
    medicationUtilizationChangeShowAll {
      medicationName
      beforePercentageOfPatientRecievedMed
      afterPercentageOfPatientRecievedMed
    }
  }
}
`;

export function getUtilizationTherapeuticCompareMedicationUtilizationChangeShowAll(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationTherapeuticCompareMedicationUtilizationChangeShowAll,
    options,
    headers
  );
}

export const utilizationTherapeuticCompareDrugPriceChangesShowAll = `query TherapeuticCompareShowMore($therapeuticSlug: String!, $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $page: Int, $size: Int, $afterEndDate: String) {
  therapeuticCompareShowMore(therapeuticSlug: $therapeuticSlug, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, page: $page, size: $size, afterEndDate: $afterEndDate) {
    drugPriceChangesShowAll {
      medicationName
      beforePrice
      afterPrice
      costDetail
    }
  }
}
`;

export function getUtilizationTherapeuticCompareDrugPriceChangesShowAll(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationTherapeuticCompareDrugPriceChangesShowAll,
    options,
    headers
  );
}

export const utilizationTherapeuticComparePatientPercentChangeShowAll = `query TherapeuticCompareShowMore($therapeuticSlug: String!, $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $page: Int, $size: Int, $afterEndDate: String) {
  therapeuticCompareShowMore(therapeuticSlug: $therapeuticSlug, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, page: $page, size: $size, afterEndDate: $afterEndDate) {
    patientPercentChangeShowAll {
      medicationName
      beforePatientPercent
      afterPatientPercent
    }
  }
}
`;

export function getUtilizationTherapeuticComparePatientPercentChangeShowAll(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationTherapeuticComparePatientPercentChangeShowAll,
    options,
    headers
  );
}

export const utilizationTherapeuticCompareMedicationUtilizationPerPatientChangeShowAll = `query TherapeuticCompareShowMore($therapeuticSlug: String!, $beforeStartDate: String, $beforeEndDate: String, $afterStartDate: String, $page: Int, $size: Int, $afterEndDate: String) {
  therapeuticCompareShowMore(therapeuticSlug: $therapeuticSlug, beforeStartDate: $beforeStartDate, beforeEndDate: $beforeEndDate, afterStartDate: $afterStartDate, page: $page, size: $size, afterEndDate: $afterEndDate) {
    medicationUtilizationPerPatientChangeShowAll {
      medicationName
      beforeUtilizationPerPatient
      afterUtilizationPerPatient
    }
  }
}
`;

export function getUtilizationTherapeuticCompareMedicationUtilizationPerPatientChangeShowAll(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationTherapeuticCompareMedicationUtilizationPerPatientChangeShowAll,
    options,
    headers
  );
}

export const utilizationMedicationComparePatientPercentChangeShowAll = `query MedicationCompareShowMore(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $therapeutics: [String]
  $size: Int
  $page: Int
) {
  MedicationCompareShowMore(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    therapeutics: $therapeutics
    size: $size
    page: $page
  ) {
    patientPercentChangeShowAll {
      therapeuticName
      beforePercentageOfPatientRecievedMed
      afterPercentageOfPatientRecievedMed
    }
  }
}
`;

export function getUtilizationMedicationComparePatientPercentChangeShowAll(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationMedicationComparePatientPercentChangeShowAll,
    options,
    headers
  );
}

export const utilizationMedicationCompareTopTherapeuticChangesShowAll = `query MedicationCompareShowMore(
  $medicationGenericName: String!
  $strengths: [String]
  $brandNames: [String]
  $ndcs: [String]
  $routes: [String]
  $hospitals: [String]
  $beforeStartDate: String
  $beforeEndDate: String
  $afterStartDate: String
  $afterEndDate: String
  $therapeutics: [String]
  $size: Int
  $page: Int
) {
  MedicationCompareShowMore(
    medicationGenericName: $medicationGenericName
    strengths: $strengths
    brandNames: $brandNames
    ndcs: $ndcs
    routes: $routes
    hospitals: $hospitals
    beforeStartDate: $beforeStartDate
    beforeEndDate: $beforeEndDate
    afterStartDate: $afterStartDate
    afterEndDate: $afterEndDate
    therapeutics: $therapeutics
    size: $size
    page: $page
  ) {
    topTherapeuticChangesShowAll {
      therapeuticName
      spendBefore
      spendAfter
    }
  }
}
`;

export function getUtilizationMedicationCompareTopTherapeuticChangesShowAll(
  options = {},
  headers = {}
) {
  return request(
    GRAPHQL_ENDPOINT,
    utilizationMedicationCompareTopTherapeuticChangesShowAll,
    options,
    headers
  );
}
